import React from "react";
import { createRoot } from "react-dom/client";
import AfterPushThroughToMatchFlow from "../../../components/sign_up/after_push_through_to_match_flow";

function init() {
  const componentElements = document.querySelectorAll(
    '[data-react-class="sign_up/after_push_through_to_match_flow"]',
  );
  componentElements.forEach((rootElement) => {
    const classString = rootElement.getAttribute("data-react-class");
    const propsJson = rootElement.getAttribute("data-react-props");
    const props = rootElement && JSON.parse(propsJson ?? "");
    if (classString) {
      const root = createRoot(rootElement);
      root.render(<AfterPushThroughToMatchFlow {...props} />);
    }
  });
}

if (document.readyState === "complete" || document.readyState === "interactive") {
  // document has at least been parsed
  init();
} else {
  document.addEventListener("DOMContentLoaded", () => {
    init();
  });
}
